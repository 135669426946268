
  import { Prop, Watch } from 'vue-property-decorator'
  import Component from 'vue-class-component'
  import { deepCopy } from '@/utils/general'
  import { BaseChart } from '../baseChart'

@Component({})
  export default class RecordDetailByCategory extends BaseChart {
  @Prop() recordsSettings: Record<string, any>
  @Prop({ default: undefined }) executives
  @Prop() targetField: string
  @Prop({ default: undefined, type: Function }) dataTransformer
  @Prop() pathToCategory: string[]
  @Prop() pathToCategoryTitle: string[]
  @Prop({ default: undefined }) filteringCriteria: Function
  @Prop({ default: false, type: Boolean }) useTotalAsTarget
  @Prop({ default: 'categoría', type: String }) categoryName
  @Prop({ default: 0, type: Number }) defaultTarget
  @Prop({ default: undefined, type: Function }) totalFilter
  @Prop({ default: false, type: Boolean }) forceProgressBar
  @Prop({ default: undefined, type: String }) totalName

  data = []

  async getData () {
    if (!this.records) return
    this.Debug('RECORDS', this.records)

    const { filteringCriteria, getObjectAttribute, getCategories, pathToCategory, pathToCategoryTitle } = this
    let records = deepCopy(this.records?.records?.aggregate?.nodes || this.records?.records?.nodes || this.records?.records?.aggregate || this.records.records || this.records || [])
    if (this.dataTransformer) {
      records = await this.dataTransformer(records)
      this.Debug('AFTER TRANSFORM DATA', records)
    }
    let allRecords = deepCopy(records)
    if (filteringCriteria) {
      records = records.filter(filteringCriteria)
      this.Debug('AFTER FILTERING DATA', records)
    }
    if (this.totalFilter) {
      allRecords = allRecords.filter(this.totalFilter)
    }

    const categories = getCategories(allRecords, pathToCategory, this.categoryName)
    const staffIDs = this.executives?.records?.map(executive => executive.id_executive)

    let data = categories.map(category => {
      const filteredByCategory = records.filter(record => getObjectAttribute(record, pathToCategory)?.id === category.id)
      const filterAllByCategory = allRecords.filter(record => getObjectAttribute(record, pathToCategory)?.id === category.id)
      const target = this.useTotalAsTarget ? filterAllByCategory.length : this.individualTarget
      return {
        id: category.id,
        title: (getObjectAttribute(category, pathToCategoryTitle) || `SIN ${this.categoryName}`).toUpperCase(),
        value: filteredByCategory.length,
        isStaff: staffIDs?.includes(category.id),
        percentage: Math.round((filteredByCategory.length / (target !== 0 ? target : 100000)) * 100),
        target,
      }
    })

    if (this.executives) {
      const dataIds = data.map(item => item.id)
      this.executives?.records?.forEach(executive => {
        if (!dataIds.includes(executive.id_executive)) {
          data.push({
            id: executive.id_executive,
            isStaff: true,
            percentage: 0,
            title: [executive.first_name?.trim(), executive.surname?.trim()].filter(item => item).join(' ').toUpperCase(),
            value: 0,
            target: 0,
          })
        }
      })
    }

    data = data.sort((prev, next) => {
      if (prev.title > next.title) {
        return 1
      } else if (prev.title < next.title) {
        return -1
      }
      return 0
    })
    data = data.sort((prev, next) => {
      if (staffIDs?.includes(prev.id)) {
        if (staffIDs?.includes(next.id)) {
          return 1
        } else {
          return -1
        }
      } else {
        return 1
      }
    })

    this.Debug('DATA', data)
    this.data = data
  }

  get individualTarget () {
    const factor = 1.2
    const executivesNumber = this.executives?.records?.length || 0
    return Math.round((this.recordsTarget / executivesNumber) * factor)
  }

  get recordsCount () {
    let sum = 0
    this.data?.forEach(item => sum += item.value)
    return sum || 0
  }

  get recordsTotal () {
    if (this.records) {
      const { records } = this
      const data = deepCopy(records?.records?.aggregate?.nodes || records?.records?.nodes || records?.records?.aggregate || records.records || records || [])
      return data.length
    }
    return 0
  }

  get recordsTarget () {
    if (this.useTotalAsTarget && this.records) {
      const { records, totalFilter, dataTransformer } = this
      let data = deepCopy(records?.records?.aggregate?.nodes || records?.records?.nodes || records?.records?.aggregate || records.records || records || [])
      if (dataTransformer) {
        data = dataTransformer(data)
      }
      if (totalFilter) {
        data = data.filter(totalFilter)
      }
      return data.length
    }
    return Math.round((this.recordsSettings?.config?.projectedUtility?.[this.targetField])) || (this.useTotalAsTarget ? this.recordsTotal : this.defaultTarget) || 0
  }

  get percentage () {
    return ((this.recordsCount || 0) / (this.recordsTarget !== 0 ? this.recordsTarget : 1)) * 100
  }

  get color () {
    return this.getBarColor(this.percentage)
  }

  get watchData () {
    const { records, recordsSettings, executives } = this
    return {
      records,
      recordsSettings,
      executives,
    }
  }

  @Watch('watchData', { immediate: true, deep: true })
  async updateData () {
    this.processingData = true
    await this.getData()
    this.processingData = false
  }
  }
